import React from "react";
import NewModal from "../../Utils/Visuales/NewModal";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { postRequest } from "../../Utils/Funciones/requester";
import Select from "react-select";
import EditButton from "../../Utils/Botones/EditButton";

const EditConfiguracionMostrarTiposDeGruposOnline = ({
  open,
  close,
  id,
  groupTypeList,
  nroFilas,
  valoresTipoClase,
  loadingMDis,
  setTableEdit,
  setLoadingMDis,
  setValoresTipoClase,
  setNroFilas,
  agregarNuevaFila,
  getDataTable,
  deleteRow,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    close(false);
  };

  const handleSubmitParametro69 = async () => {
    const stringValor = valoresTipoClase.map((valor) => valor.value);
    const json = [
      {
        nombre: "tipo_grupo",
        valor: stringValor.join(),
        tipo_asistencia: ["periodo"],
      },
    ];
    try {
      setLoadingMDis(true);
      const payload = {
        id,
        data: json,
      };
      const response = await postRequest("/parametro_reglas", payload);

      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setTableEdit(false);
        getDataTable(id);
        close(false);
        setNroFilas([]);
        setValoresTipoClase([]);
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } finally {
      setLoadingMDis(false);
    }
  };

  return (
    <NewModal
      title={`Configuración Mostrar Tipos De Grupos Online`}
      open={open}
      handleClose={handleClose}
      maxWidth={800}
      height={600}
      loading={loadingMDis}
      overflowY={"unset"}
      handleSubmit={handleSubmitParametro69}
    >
      <Grid
        item
        xs={12}
        className="consult-tab-option_center"
        style={{ paddingTop: 20 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            marginBottom: "2%",
            marginRight: "5%",
            height: "30px",
          }}
        >
          <EditButton onClick={() => agregarNuevaFila(nroFilas)}>
            Agregar
          </EditButton>
        </div>
        <div>
          <table
            className="input-table"
            style={{ width: "78%", marginTop: "1%" }}
          >
            <tr>
              <th>Tipos de Grupos</th>
            </tr>
            {nroFilas.map((e, i) => (
              <tr key={i}>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Select
                      id="proyecciones"
                      className="select-modal-container"
                      classNamePrefix="select-modal"
                      styles={{
                        maxWidth: 604,
                        menu: (styles) => ({
                          ...styles,
                          zIndex: 999,
                        }),
                        indicatorsContainer: (styles) => ({
                          ...styles,
                        }),
                      }}
                      options={groupTypeList.filter(
                        (group) =>
                          !valoresTipoClase.some(
                            (clase) => clase.value === group.value
                          )
                      )}
                      value={valoresTipoClase?.[i]}
                      onChange={(e) => {
                        const nuevosValores = [...valoresTipoClase];
                        nuevosValores[i] = e;
                        setValoresTipoClase(nuevosValores);
                      }}
                    />
                  </div>
                </td>

                <td>
                  <div
                    className="table-delete"
                    style={{ marginInline: "auto", zIndex: 99 }}
                    onClick={() => deleteRow(i)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </div>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </Grid>
    </NewModal>
  );
};

export default EditConfiguracionMostrarTiposDeGruposOnline;
