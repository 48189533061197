import { getRequest } from "./requester";
import { enqueueSnackbar } from "notistack";

export const getMateriaOperartiva = async () => {
  try {
    const consulta = `/catalogo?id=35`;
    const response = await getRequest(consulta);
    let data = [];
    if (response.s === "OK") {
      let id = response.d.elementos[0].id;
      let clave = response.d.elementos[1].Clave;
      let estatus = response.d.elementos[2].Estatus;
      let modificado = response.d.elementos[3].Modificado;
      let modificado_por = response.d.elementos[4]["Modificado Por"];
      let creado = response.d.elementos[5].Creado;
      let creado_por = response.d.elementos[6]["Creado Por"];

      id.forEach((item, i) => {
        data.push({
          value: id[i],
          label: `${clave[i]}`,
          clave: clave[i],
          estatus: estatus[i],
          creado_por: creado_por[i],
          creado: creado[i],
          modificado_por: modificado_por[i],
          modificado: modificado[i],
        });
      });
      return data;
    } else {
      enqueueSnackbar(response.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  } catch (error) {
    console.error(error);
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
  }
};
