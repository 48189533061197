export const materiasOperativasHeaders = [
  {
    accessorKey: "Clave",
    header: "Clave",
    enableEditing: false,
    enableGlobalFilter: true,
  },
  {
    accessorKey: "Estatus",
    header: "Estatus",
    size: 80,
    enableEditing: false,
    enableGlobalFilter: true,
  },
  {
    accessorKey: "Modificado",
    header: "Modificado",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Modificado Por",
    header: "Modificado Por",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Creado",
    header: "Creado",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Creado Por",
    header: "Creado Por",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "id",
    header: "id",
    size: 10,
    enableEditing: false,
    enableGlobalFilter: false,
  },
];
