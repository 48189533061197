import React from "react";
import { Grid, Box } from "@material-ui/core";
import NewButton from "../Botones/NewButton";
import MaterialReactTable from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MenuItem } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { getSchoolsClave } from "../Funciones/getSchools";
import { getGroupType } from "../Funciones/getGroupType";
import { getTabulators } from "../Funciones/getTabulators";
import convertSnakeToTitle from "../Funciones/convertSnakeToTitle";
// import "./styles.css";

export default function CustomTable({
  data,
  headers,
  loading,
  handleClickCancelar,
  isEditarDisabled = false,
  handleClickSave,
  isRenderTopToolbarCustomActions = false,
  topToolBardComponent,
  isFooterSaveButtons = true,
  isDeleteButton = true,
  onEditingRowSave = () => {},
  handleDeleteRow = () => {},
  setEditarParam50,
  setFormDataParam50,
  setEditarParam58,
  setEditarParam62,
  setEditarParam64,
  setEditarParam66,
  setValoresTipoClase,
  setLoadingMDis,
  setEscuelasSelectedOptions,
  setTipoGrupoSelectedOptions,
  setTabuladoresSelectedOptions,
  setEstatusSelectedOptions,
  setEscuelasCatalogo,
  setTipoGrupoCatalogo,
  setTabuladoresCatalogo,
  setFilaEditar,
  setValoresPorcentaje,
  valoresModalDis,
  setNroFilas,
  valueProceso,
  setEditarParam67,
  setEditarParam68,
  setEditarParam69,
}) {
  if (headers[3]?.id === "Distribucion") {
    headers.forEach((element) => {
      if (element.id !== "Distribucion") {
        element.enableResizing = false;
      }
    });
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          marginBottom: isFooterSaveButtons ? 40 : 20,
          "& .super-app-theme--false": {
            bgcolor: "#e9e9e9",
            "&:hover": {
              bgcolor: "#cbcbcb",
            },
          },
        }}
      >
        <MaterialReactTable
          columns={headers}
          data={data}
          editingMode="row"
          enableEditing={isEditarDisabled}
          onEditingRowSave={onEditingRowSave}
          enableRowActions={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableTopToolbar={true}
          enableBottomToolbar
          localization={MRT_Localization_ES}
          enableColumnActions={false}
          enableGlobalFilterModes
          enableColumnResizing
          columnResizeMode="onChange"
          positionGlobalFilter="right"
          muiSearchTextFieldProps={{
            elevation: 0,
            placeholder: `Buscar`,
            sx: {
              ".css-152mnda-MuiInputBase-input-MuiOutlinedInput-input": {
                paddingY: 0,
              },
              width: "400px",
              borderRadius: "15px",
              border: "1px solid #ccc",
              padding: "0 15px",
              fontSize: "15px",
              paddingY: 0,
              marginY: 0,
            },
            variant: "outlined",
          }}
          initialState={{
            showGlobalFilter: true,
          }}
          enableToolbarInternalActions={false}
          state={{ isLoading: loading }}
          filterFns={{
            myCustomFilterFn: (row, id, filterValue) => {
              if (
                row
                  .getValue(id)
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(filterValue?.toLowerCase())
              )
                return true;
              if (
                row.original?.profesor?.[0]?.label
                  ?.toLowerCase()
                  ?.includes(filterValue?.toLowerCase())
              )
                return true;
              return false;
            },
          }}
          globalFilterFn="myCustomFilterFn"
          renderTopToolbarCustomActions={() => {
            if (isRenderTopToolbarCustomActions) {
              return topToolBardComponent;
            }
            return null;
          }}
          renderRowActionMenuItems={
            headers[3]?.id !== "Distribucion"
              ? ({ row, closeMenu }) => {
                  if (!isDeleteButton) {
                    return null;
                  } else {
                    return (
                      <Box
                        sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}
                      >
                        <MenuItem
                          key="delete"
                          onClick={() => {
                            handleDeleteRow(row.original);
                            closeMenu();
                          }}
                        >
                          <DeleteIcon
                            color="error"
                            style={{ marginRight: 12 }}
                          />
                          Eliminar
                        </MenuItem>
                      </Box>
                    );
                  }
                }
              : false
          }
          renderRowActions={
            headers[3]?.id === "Distribucion"
              ? ({ row }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <NewButton
                      customText="Editar"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        setFilaEditar(row.id);
                        const porcentajes =
                          valoresModalDis[row.id].Distribucion;
                        const arregloPorcentajes = porcentajes
                          .split(", ")
                          .map((item) => {
                            const [numero, porcentaje] = item.split(" => ");
                            return [parseInt(numero), parseInt(porcentaje)];
                          });

                        setNroFilas(arregloPorcentajes);
                        setValoresPorcentaje(arregloPorcentajes);
                        setEditarParam58(true);
                      }}
                    />
                  </Box>
                )
              : valueProceso === 62
              ? ({ row }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <NewButton
                      customText="Editar"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        setLoadingMDis(true);
                        Promise.all([
                          getSchoolsClave(),
                          getGroupType(),
                          getTabulators(),
                        ])
                          .then(
                            ([schoolsData, groupTypeData, tabulatorsData]) => {
                              setEscuelasCatalogo(schoolsData);
                              setTipoGrupoCatalogo(groupTypeData);
                              setTabuladoresCatalogo(tabulatorsData);
                              setEscuelasSelectedOptions(
                                schoolsData.find(
                                  (escuela) =>
                                    escuela.value === row.original.clave_escuela
                                )
                              );
                              setTipoGrupoSelectedOptions(
                                groupTypeData.find(
                                  (tipoGrupo) =>
                                    tipoGrupo.value === row.original.tipo_grupo
                                )
                              );
                              setTabuladoresSelectedOptions(
                                tabulatorsData.find(
                                  (tabulador) =>
                                    tabulador.label === row.original.tabulador
                                )
                              );
                              setEstatusSelectedOptions({
                                value:
                                  row.original.estatus.charAt(0).toUpperCase() +
                                  row.original.estatus.slice(1),
                                label:
                                  row.original.estatus.charAt(0).toUpperCase() +
                                  row.original.estatus.slice(1),
                              });
                            }
                          )
                          .catch((error) => {
                            // setEditarParam62(false);
                          })
                          .finally(() => {
                            setLoadingMDis(false);
                          });

                        setEditarParam62(true);
                      }}
                    />
                  </Box>
                )
              : valueProceso === 50
              ? ({ row }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <NewButton
                      customText="Editar"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        setFilaEditar(row.id);
                        setFormDataParam50((prevState) => ({
                          ...prevState,
                          clve_camp_fnza: row.original.clve_camp_fnza,
                        }));
                        setFormDataParam50((prevState) => ({
                          ...prevState,
                          clve_crra: row.original.clve_crra,
                        }));
                        setFormDataParam50((prevState) => ({
                          ...prevState,
                          porcentaje: row.original.porcentaje,
                        }));
                        setEstatusSelectedOptions({
                          value:
                            row.original.estatus.charAt(0).toUpperCase() +
                            row.original.estatus.slice(1),
                          label:
                            row.original.estatus.charAt(0).toUpperCase() +
                            row.original.estatus.slice(1),
                        });
                        setEditarParam50(true);
                      }}
                    />
                  </Box>
                )
              : valueProceso === 64
              ? ({ row }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <NewButton
                      customText="Editar"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        const arregloFilas = row.original.valor
                          .split(", ")
                          .map((item) => {
                            const [clave, clase] = item.split(" - ");
                            return {
                              label: `${clave} - ${clase}`,
                              value: clave,
                            };
                          });

                        setNroFilas(arregloFilas);
                        setValoresTipoClase(arregloFilas);
                        setEditarParam64(true);
                      }}
                    />
                  </Box>
                )
              : valueProceso === 66
              ? ({ row }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <NewButton
                      customText="Editar"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        const arregloFilas = row.original.valor
                          .split(", ")
                          .map((item) => {
                            const [clave, clase] = item.split(" - ");
                            return {
                              original: row.original.nombre,
                              nombre: convertSnakeToTitle(row.original.nombre),
                              label: `${clave} - ${clase}`,
                              value: clave,
                            };
                          });
                        setNroFilas(arregloFilas);
                        setValoresTipoClase(arregloFilas);
                        setEditarParam66(true);
                      }}
                    />
                  </Box>
                )
              : valueProceso === 67
              ? ({ row }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <NewButton
                      customText="Editar"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        const arregloFilas = row.original.tipos_grupos
                          .split(",")
                          .map((item) => {
                            return {
                              original: row.original.modalidad,
                              nombre: row.original.modalidad,
                              label: item,
                              value: item,
                            };
                          });

                        setNroFilas(arregloFilas);
                        setValoresTipoClase(arregloFilas);
                        setEditarParam67(true);
                      }}
                    />
                  </Box>
                )
              : valueProceso === 68
              ? ({ row }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <NewButton
                      customText="Editar"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        setValoresTipoClase(row.original);
                        setEditarParam68(true);
                      }}
                    />
                  </Box>
                )
              : valueProceso === 69
              ? ({ row }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <NewButton
                      customText="Editar"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        const arregloFilas = row.original.valor
                          ?.replace(/\s/g, "")
                          ?.split(",")
                          ?.map((item) => {
                            return {
                              tipo_asistencia: "periodo",
                              label: item,
                              value: item,
                            };
                          });

                        setNroFilas(arregloFilas);
                        setValoresTipoClase(arregloFilas);
                        setEditarParam69(true);
                      }}
                    />
                  </Box>
                )
              : false
          }
        />
      </Box>
      {isFooterSaveButtons ? null : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 40,
          }}
        >
          <Grid item xs={12} sm={1}>
            <NewButton
              customText="Cancelar"
              noIcon
              style={{ padding: "5px 15px", backgroundColor: "#aca7a1" }}
              onClick={handleClickCancelar}
            />
          </Grid>
          <Grid item sm={1} style={{ marginLeft: "10%" }}>
            <NewButton
              customText="Guardar"
              noIcon
              style={{ padding: "5px 15px" }}
              onClick={handleClickSave}
            />
          </Grid>
        </div>
      )}
    </>
  );
}
