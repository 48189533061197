import { getRequest } from "./requester";
import { enqueueSnackbar } from "notistack";

export const getTraduccionInterfaceNominaagrupadorHorasOnline = async () => {
  try {
    const consulta = `/parametro_reglas?id=68`;
    const response = await getRequest(consulta);
    if (response.s === "OK") {
      return response.d.data.map((agrupador) => ({
        ...agrupador,
        value: agrupador.agrupador,
        label: agrupador.agrupador,
        clave: `${agrupador.agrupador}`,
      }));
    } else {
      enqueueSnackbar(response.m, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  } catch (error) {
    console.error(error);
    enqueueSnackbar(error, {
      variant: "error",
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "top",
        horizontal: "left",
      },
    });
  }
};
