import React, { useState, useEffect } from "react";
import Contenedor from "../../Utils/Visuales/Contenedor";
import { getRequest, postRequest } from "../../Utils/Funciones/requester";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid } from "@material-ui/core";
import Switch from "@mui/material/Switch";
import { CustomSpinner } from "../../Utils/Loader";
import NewModal from "../../Utils/Visuales/NewModal";
import { fixCatalogoName } from "../../Utils/Funciones/fixCatalogoName";
import NewButton from "../../Utils/Botones/NewButton";
import SwitchModal from "../../Utils/SwitchModal";

import { campusHeaders } from "../../Utils/data/campusHeaders";
import { getDefaultCatalogoHeaders } from "../../Utils/Funciones/getDefaultCatalogoHeaders";
import { dictamenHeaders } from "../../Utils/data/dictamenHeaders";
import moment from "moment";
import { carreraHeaders } from "../../Utils/data/carreraHeaders";
import { conceptoPEHeaders } from "../../Utils/data/conceptoPEHeaders";
import { escuelaHeaders } from "../../Utils/data/escuelasHeaders";
import { direccionesHeaders } from "../../Utils/data/direccionesHeaders";
import { extraordinaryPaymentHeaders } from "../../Utils/data/extraordinaryPaymentHeaders";
import { academicDegreeHeaders } from "../../Utils/data/academicDegreeHeaders";
import { typeClass } from "../../Utils/data/typeClass";
import { typeSalon } from "../../Utils/data/typeSalon";
import { teacherTypeHeaders } from "../../Utils/data/teacherTypeHeaders";
import { typeDisabilitiesHeaders } from "../../Utils/data/typeDisabilitiesHeaders";
import { editCatalogo } from "../../Utils/Funciones/editCatalogo";
import { calendarioQuincenaHeaders } from "../../Utils/data/calendarioQuincenaHeaders";
import { professionsHeaders } from "../../Utils/data/professionsHeaders";
import { tabuladoresHeaders } from "../../Utils/data/tabuladoresHeaders";
import { validateQuincena } from "../../Utils/Funciones/validateQuincena";
import {
  handleSubmitCreateModal,
  handleSubmitEditModal,
} from "./utils/functions";
import CustomTableCatalogo from "../../Utils/CustomTableCatalogo";
import EditMateriaAgrupadorHorasOnlineExcepcion from "../Modals/EditMateriaAgrupadorHorasOnlineExcepcion";
import { materiaAgrupadorHorasOnlineExcepcionHeaders } from "../../Utils/data/MateriaAgrupadorHorasOnlineExcepcionHeaders";
import { materiasOperativasHeaders } from "../../Utils/data/materiasOperativasHeaders";

export const Catalogos = () => {
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [dataFinal, setDataFinal] = useState([]);
  const [getHeaders, setGetHeaders] = useState([]);
  const [valueCatalogo, setValueCatalogo] = useState("");
  const [listCatalogo, setListCatalogo] = useState([]);
  const [tableEdit, setTableEdit] = useState(true);
  const [isTableEdit, setIsTableEdit] = useState(false);
  const { permisos, userName } = useSelector((state) => state);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [deleteRow, setDeleteRow] = useState({});
  const [catalogoName, setCatalogoName] = useState("");
  const [clave, setClave] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [maxImport, setMaxImport] = useState("");
  const [showImporte, setShowImporte] = useState("");
  const [showSchedule, setShowSchedule] = useState("");
  const [year, setYear] = useState("");
  const [number, setNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [processDate, setProcessDate] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [name, setName] = useState("");
  const [tabulador, setTabulador] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [newData, setNewData] = useState({});

  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    switch (valueCatalogo) {
      case 14:
        const newStartDate = `${values?.fecha_inicio?.split("-")?.[2]}-${
          values?.fecha_inicio?.split("-")?.[1]
        }-${values?.fecha_inicio?.split("-")?.[0]}`;
        const newEndDate = `${values?.fecha_fin?.split("-")?.[2]}-${
          values?.fecha_fin?.split("-")?.[1]
        }-${values?.fecha_fin?.split("-")?.[0]}`;
        const newProcessDate = `${values?.fecha_proceso?.split("-")?.[2]}-${
          values?.fecha_proceso?.split("-")?.[1]
        }-${values?.fecha_proceso?.split("-")?.[0]}`;
        const oldProcessDate = `${
          row.original.fecha_proceso?.split("-")?.[2]
        }-${row.original.fecha_proceso?.split("-")?.[1]}-${
          row.original.fecha_proceso?.split("-")?.[0]
        }`;
        const newPaymentDate = `${values?.fecha_pago?.split("-")?.[2]}-${
          values?.fecha_pago?.split("-")?.[1]
        }-${values?.fecha_pago?.split("-")?.[0]}`;
        if (moment().isSameOrAfter(oldProcessDate)) {
          enqueueSnackbar(
            "No se puede editar un registro con una fecha de proceso menor o igual a la de hoy",
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          break;
        }
        values["fecha_inicio"] = newStartDate;
        values["fecha_fin"] = newEndDate;
        values["fecha_proceso"] = newProcessDate;
        values["fecha_pago"] = newPaymentDate;

        const isValid = validateQuincena(
          values.año,
          values["Número Quincena"],
          newStartDate,
          newEndDate,
          newProcessDate,
          newPaymentDate,
          dataFinal,
          row.original.id
        );
        if (isValid) {
          await editCatalogo(
            listCatalogo,
            valueCatalogo,
            row,
            values,
            dataFinal,
            getDataTable,
            setLoading,
            setShowTable,
            setTableEdit,
            exitEditingMode
          );
          break;
        }
        break;
      case 34:
        const estatus =
          values.estatus.charAt(0).toUpperCase() +
          values.estatus.slice(1).toLowerCase();
        if (estatus !== "Activo" && estatus !== "Inactivo") {
          enqueueSnackbar(
            "Los valores permitidos en Estatus son Activo o Inactivo",
            {
              variant: "error",
              autoHideDuration: null,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
          return;
        }
        values.estatus = estatus;
        await editCatalogo(
          listCatalogo,
          valueCatalogo,
          row,
          values,
          dataFinal,
          getDataTable,
          setLoading,
          setShowTable,
          setTableEdit,
          exitEditingMode
        );
        break;
      default:
        await editCatalogo(
          listCatalogo,
          valueCatalogo,
          row,
          values,
          dataFinal,
          getDataTable,
          setLoading,
          setShowTable,
          setTableEdit,
          exitEditingMode
        );
        break;
    }
  };

  const GetListCatalogo = async () => {
    try {
      setLoading(true);
      setTableEdit(false);
      const request = `/catalogo?tipo=catalogo`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        let arrayResponse = response.d.map((e) => {
          return fixCatalogoName(e);
        });
        setListCatalogo(arrayResponse);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDataTable = async (e, check = true) => {
    try {
      setShowTable(false);
      setTableEdit(check);
      const getCampus = listCatalogo.filter(
        (element) => element.value === e
      )[0];
      setIsTableEdit(getCampus.editable);
      setValueCatalogo(e);
      setCatalogoName(getCampus.label.split("-")[1]);
      setLoading(true);
      const request = `/catalogo?id=${e}${e === 7 ? "&escuelas=True" : ""}`;
      const response = await getRequest(request);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        var carrera_clave_edit = false;
        //header
        switch (e) {
          case 1:
            setGetHeaders(dictamenHeaders);
            break;
          case 3:
            setGetHeaders(teacherTypeHeaders);
            break;
          case 5:
            setGetHeaders(campusHeaders);
            break;
          case 6:
            setGetHeaders(conceptoPEHeaders);
            break;
          case 7:
            setGetHeaders(direccionesHeaders);
            break;
          case 8:
            setGetHeaders(carreraHeaders);
            carrera_clave_edit = true;
            break;
          case 9:
            setGetHeaders(escuelaHeaders);
            break;
          case 10:
          case 12:
          case 13:
            setGetHeaders(extraordinaryPaymentHeaders);
            break;
          case 14:
            setGetHeaders(calendarioQuincenaHeaders);
            break;
          case 15:
            setGetHeaders(typeDisabilitiesHeaders);
            break;
          case 16:
          case 17:
          case 19:
          case 21:
          case 26:
          case 27:
          case 31:
            setGetHeaders(academicDegreeHeaders);
            break;
          case 18:
            setGetHeaders(professionsHeaders);
            break;
          case 22:
          case 30:
            setGetHeaders(typeClass);
            break;
          case 24:
            setGetHeaders(typeSalon);
            break;
          case 34:
            setGetHeaders(tabuladoresHeaders);
            break;
          case 35:
            setGetHeaders(materiasOperativasHeaders);
            break;
          case 36:
            setGetHeaders(materiaAgrupadorHorasOnlineExcepcionHeaders);
            break;
          default:
            setGetHeaders(getDefaultCatalogoHeaders(response.d.elementos, e));
            break;
        }

        //data
        const array1 = [];
        const array2 = [];
        var arrayRows = [];
        for (let i = 0; i < response.d.elementos.length; i++) {
          const objp = Object.values(response.d.elementos[i]);
          array1[i] = objp;
        }
        const numRows_ = array1[0][0].length;

        for (let step = 0; step < numRows_; step++) {
          for (let i = 0; i < response.d.elementos.length; i++) {
            Object.entries(response.d.elementos[i]).forEach((value) => {
              let numj = i - i + step;
              if (value[0] === "creado" || value[0] === "modificado") {
                array2[value[0]] =
                  value[1][numj] === "None"
                    ? ""
                    : moment(value[1][numj].toString()).format(
                        "DD-MM-YYYY HH:mm:ss"
                      );
              } else if (
                value[0] === "fecha_pago" ||
                value[0] === "fecha_proceso" ||
                value[0] === "fecha_fin" ||
                value[0] === "fecha_inicio"
              ) {
                array2[value[0]] =
                  value[1][numj] === "None"
                    ? ""
                    : moment(value[1][numj].toString()).format("DD-MM-YYYY");
              } else {
                array2[value[0]] =
                  value[1][numj] === "None" ? "" : value[1][numj];
              }
            });
          }
          arrayRows.push({ ...array2 });
        }
        if (carrera_clave_edit) {
          arrayRows.map((item) => {
            item.clave = item.clave.split(".")[0];
            return item;
          });
        }
        setShowTable(true);
        setDataFinal(
          arrayRows.sort((a, b) =>
            a?.clave > b?.clave ? 1 : b?.clave > a?.clave ? -1 : 0
          )
        );
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setShowTable(false);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickEditTable = (event) => {
    setShowTable(false);
    setTableEdit(event.target.checked);
    getDataTable(valueCatalogo, event.target.checked);
  };

  const handleClickSelect = (value) => {
    setIsTableEdit(false);
    getDataTable(value, false);
  };

  const handleDeleteRow = (row) => {
    if (
      valueCatalogo === 14 &&
      moment().isSameOrAfter(
        `${row.fecha_proceso?.split("-")?.[2]}-${
          row.fecha_proceso?.split("-")?.[1]
        }-${row.fecha_proceso?.split("-")?.[0]}`
      )
    ) {
      enqueueSnackbar(
        "No se puede eliminar un registro con una fecha de proceso menor o igual a la de hoy",
        {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }
      );
    } else {
      setShowDeleteModal(true);
      setDeleteRow(row);
    }
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteRow({});
  };

  const handleSubmitDeleteModal = async () => {
    try {
      const getCampus = listCatalogo.filter(
        (element) => element.value === valueCatalogo
      )[0];
      const payload = {
        type: `${getCampus.name}-delete`,
        id: parseInt(deleteRow.id),
      };
      delete payload.creado;
      delete payload.creado_por;
      delete payload.modificado;
      delete payload.modificado_por;
      setShowDeleteModal(false);
      setLoading(true);
      setShowTable(false);
      const response = await postRequest(`/catalogo`, payload);
      if (response.s === "OK") {
        enqueueSnackbar(response.m, {
          variant: "success",
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        setTableEdit(false);
        getDataTable(valueCatalogo);
      } else {
        setShowDeleteModal(false);
        setLoading(false);
        setShowTable(true);
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.log(error);
      setShowDeleteModal(false);
      setLoading(false);
      setShowTable(true);
    }
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
    cleanState();
    setIsModalLoading(false);
  };

  const handleOnClickEdit = (row) => {
    setShowEditModal(true);
    switch (valueCatalogo) {
      case 36:
        setNewData(row.original);
        break;
      default:
        break;
    }
  };

  const cleanState = () => {
    setClave("");
    setDescription("");
    setType("");
    setMaxImport("");
    setShowImporte("");
    setShowSchedule("");
    setYear("");
    setNumber("");
    setStartDate("");
    setEndDate("");
    setProcessDate("");
    setPaymentDate("");
    setTabulador("");
    setNewData({});
    setShowEditModal(false);
    setIsModalLoading(false);
    setShowCreateModal(false);
  };

  useEffect(() => {
    GetListCatalogo();
  }, []);

  return (
    <>
      <CustomSpinner visible={loading} />
      <Contenedor title="Configuración / Catálogos">
        <Grid container style={{ marginBottom: 20, marginTop: 10 }}>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              marginLeft: 2,
            }}
          >
            <Grid
              container
              style={{ width: "90%", display: "flex", alignItems: "center" }}
            >
              <Grid xs={6} sm={1} item style={{ marginRight: 10 }}>
                <label>Catálogo:</label>
              </Grid>
              <Grid xs={6} sm={5} item style={{ padding: 2, zIndex: 99 }}>
                <Select
                  className="select-modal-container"
                  classNamePrefix="select-modal"
                  value={valueCatalogo.value}
                  options={listCatalogo}
                  onChange={(e) => {
                    handleClickSelect(e.value);
                  }}
                />
              </Grid>
              {isTableEdit && showTable && (
                <>
                  <Grid item xs={12} sm={2} style={{ marginLeft: 20 }}>
                    <FormGroup>
                      <FormControlLabel
                        style={{
                          display:
                            permisos["Configuración->Cátalogos"].modificar ===
                            "1"
                              ? "block"
                              : "none",
                        }}
                        control={
                          <Switch
                            checked={tableEdit}
                            onChange={handleClickEditTable}
                          />
                        }
                        label="Editar"
                      />
                    </FormGroup>
                  </Grid>
                </>
              )}
            </Grid>
            {isTableEdit && showTable && valueCatalogo !== 5 && (
              <Grid container item xs={12} sm={1} spacing={2}>
                <Grid
                  container
                  item
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 16,
                  }}
                >
                  <div style={{ display: "flex", marginLeft: "-10%" }}>
                    <NewButton
                      customText="Crear"
                      noIcon
                      style={{
                        marginLeft: "12%",
                        padding: "5px 15px",
                        display:
                          permisos["Configuración->Divisionales"].escribir ===
                          "1"
                            ? "block"
                            : "none",
                      }}
                      disabled={false}
                      onClick={() => {
                        setShowCreateModal(true);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {showCreateModal && (
          <NewModal
            title={`Crear ${catalogoName}`}
            open={showCreateModal}
            handleClose={handleCloseCreateModal}
            loading={isModalLoading}
            handleSubmit={() => {
              handleSubmitCreateModal(
                valueCatalogo,
                clave,
                description,
                type,
                maxImport,
                showImporte,
                showSchedule,
                dataFinal,
                setShowCreateModal,
                setLoading,
                setShowTable,
                setTableEdit,
                getDataTable,
                year,
                number,
                startDate,
                endDate,
                processDate,
                paymentDate,
                cleanState,
                name,
                tabulador,
                enqueueSnackbar,
                newData,
                setIsModalLoading
              );
            }}
            overflowY={"unSet"}
          >
            <SwitchModal
              catalogo={valueCatalogo}
              year={year}
              number={number}
              startDate={startDate}
              endDate={endDate}
              processDate={processDate}
              paymentDate={paymentDate}
              newData={newData}
              setYear={setYear}
              setNumber={setNumber}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setProcessDate={setProcessDate}
              setPaymentDate={setPaymentDate}
              setName={setName}
              setTabulador={setTabulador}
              setIsModalLoading={setIsModalLoading}
              setNewData={setNewData}
              setClave={setClave}
              setDescription={setDescription}
              setType={setType}
              setMaxImport={setMaxImport}
              setShowImporte={setShowImporte}
              setShowSchedule={setShowSchedule}
            />
          </NewModal>
        )}
        {showTable && (
          <CustomTableCatalogo
            data={loading ? [] : dataFinal}
            headers={getHeaders}
            loading={loading}
            isEditarDisabled={tableEdit}
            onEditingRowSave={handleSaveRow}
            handleDeleteRow={handleDeleteRow}
            valueProceso={valueCatalogo}
            handleOnClickEdit={handleOnClickEdit}
          />
        )}
        <NewModal
          overflowY={"unSet"}
          title={`Editar ${catalogoName}`}
          open={showEditModal}
          loading={isModalLoading}
          handleClose={handleCloseCreateModal}
          handleSubmit={() => {
            handleSubmitEditModal(
              userName,
              valueCatalogo,
              cleanState,
              enqueueSnackbar,
              newData,
              getDataTable,
              setLoading,
              setShowTable,
              setTableEdit,
              setIsModalLoading
            );
          }}
        >
          <EditMateriaAgrupadorHorasOnlineExcepcion
            setNewData={setNewData}
            newData={newData}
            enqueueSnackbar={enqueueSnackbar}
          />
        </NewModal>
        {showDeleteModal ? (
          <NewModal
            title="Desactivar Fila"
            open={showDeleteModal}
            handleClose={handleCloseDeleteModal}
            handleSubmit={handleSubmitDeleteModal}
          >
            <Grid
              container
              style={{
                padding: 10,
                width: "100%",
                textAlign: "cente",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <p>¿Estás seguro de eliminar este registro?</p>
            </Grid>
          </NewModal>
        ) : null}
      </Contenedor>
    </>
  );
};
