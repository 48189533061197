import moment from "moment";
import { createCatalogo } from "../../../Utils/Funciones/createCatalogo";
import { editCatalogoModal } from "../../../Utils/Funciones/editCatalogo";
import { validateConceptoPago } from "../../../Utils/Funciones/validateConceptoPago";
import { validateProfesion } from "../../../Utils/Funciones/validateProfesion";
import { validateQuincena } from "../../../Utils/Funciones/validateQuincena";

export const handleSubmitCreateModal = (
  valueCatalogo,
  clave,
  description,
  type,
  maxImport,
  showImporte,
  showSchedule,
  dataFinal,
  setShowCreateModal,
  setLoading,
  setShowTable,
  setTableEdit,
  getDataTable,
  year,
  number,
  startDate,
  endDate,
  processDate,
  paymentDate,
  cleanState,
  name,
  tabulador,
  enqueueSnackbar,
  newData,
  setIsModalLoading
) => {
  let body = {};
  switch (valueCatalogo) {
    case 6: {
      const isValid = validateConceptoPago(
        clave,
        description,
        type,
        maxImport,
        showImporte,
        showSchedule,
        dataFinal
      );
      if (isValid) {
        body = {
          clave,
          description,
          type: type.value,
          maxImport: parseFloat(maxImport),
          showImporte: showImporte.value,
          showSchedule: showSchedule.value,
        };
        createCatalogo(
          valueCatalogo,
          "concepto_pagoextraordinario-create",
          body,
          setShowCreateModal,
          setLoading,
          setShowTable,
          setTableEdit,
          getDataTable,
          cleanState,
          setIsModalLoading
        );
      }
      break;
    }
    case 14: {
      const isValid = validateQuincena(
        year,
        number,
        startDate,
        endDate,
        processDate,
        paymentDate,
        dataFinal.filter((data) => data.año === year)
      );
      if (isValid) {
        body = {
          year,
          number: parseFloat(number),
          startDate,
          endDate,
          processDate,
          paymentDate,
        };
        createCatalogo(
          valueCatalogo,
          "Calendario Quincena-create",
          body,
          setShowCreateModal,
          setLoading,
          setShowTable,
          setTableEdit,
          getDataTable,
          cleanState,
          setIsModalLoading
        );
      }
      break;
    }
    case 18: {
      const isValid = validateProfesion(clave, name, dataFinal);
      if (isValid) {
        body = {
          clave,
          name: name.toUpperCase(),
        };
        createCatalogo(
          valueCatalogo,
          "Tipo Profesión-create",
          body,
          setShowCreateModal,
          setLoading,
          setShowTable,
          setTableEdit,
          getDataTable,
          cleanState,
          setIsModalLoading
        );
      }
      break;
    }
    case 26: {
      const isValid = validateProfesion(clave, name, dataFinal);
      if (isValid) {
        body = {
          clave,
          name,
        };
        createCatalogo(
          valueCatalogo,
          "Tipo Persona-create",
          body,
          setShowCreateModal,
          setLoading,
          setShowTable,
          setTableEdit,
          getDataTable,

          cleanState,
          setIsModalLoading
        );
      }
      break;
    }

    case 27: {
      const isValid = validateProfesion(clave, name, dataFinal);
      if (isValid) {
        body = {
          clave,
          name,
        };
        createCatalogo(
          valueCatalogo,
          "Motivo baja-create",
          body,
          setShowCreateModal,
          setLoading,
          setShowTable,
          setTableEdit,
          getDataTable,
          cleanState,
          setIsModalLoading
        );
      }
      break;
    }
    case 34: {
      if (!tabulador) {
        enqueueSnackbar("Por favor llenar todos los campos", {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        return false;
      }

      body = {
        tabulador,
        estatus: "Activo",
      };
      createCatalogo(
        valueCatalogo,
        "Tabuladores-create",
        body,
        setShowCreateModal,
        setLoading,
        setShowTable,
        setTableEdit,
        getDataTable,
        cleanState,
        setIsModalLoading
      );
      break;
    }
    case 36: {
      const campusList = newData?.campus
        ?.filter((item) => item?.clave)
        ?.map((item) => item.clave)
        ?.join(",");
      if (
        !newData.escuelas?.value ||
        !newData.materia?.value ||
        !newData.agrupador?.value ||
        !newData?.campus?.length
      ) {
        enqueueSnackbar("Por favor llenar todos los campos", {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        return false;
      } else {
        body = {
          id_escuela: `${newData.escuelas.value}`,
          id_materia_operativa: `${newData.materia.value}`,
          id_agrupador: `${newData.agrupador.value}`,
          lista_campus_aplica: campusList,
          estatus: "1",
        };
        createCatalogo(
          valueCatalogo,
          "Materia Agrupador Horas Online Excepción-create",
          body,
          setShowCreateModal,
          setLoading,
          setShowTable,
          setTableEdit,
          getDataTable,
          cleanState,
          setIsModalLoading
        );
      }
      break;
    }
    default:
      break;
  }
};

export const handleSubmitEditModal = (
  userName,
  valueCatalogo,
  cleanState,
  enqueueSnackbar,
  newData,
  getDataTable,
  setLoading,
  setShowTable,
  setTableEdit,
  setIsModalLoading
) => {
  switch (valueCatalogo) {
    case 36: {
      if (!("campus" in newData)) {
        newData.campus = newData.Campus;
      }
      const campusList =
        typeof newData?.campus !== "string"
          ? newData?.campus
              ?.filter((item) => item?.clave)
              ?.map((item) => item.clave)
              ?.join(",")
          : newData?.Campus;
      if (
        !newData.escuelas?.value ||
        !newData.materia?.value ||
        newData.agrupador?.value === null ||
        newData.agrupador?.value === undefined ||
        !newData?.campus?.length ||
        !newData.estatus?.value
      ) {
        enqueueSnackbar("Por favor llenar todos los campos", {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
        return false;
      } else {
        const payload = {
          "Clave Escuela": `${newData.escuelas.clave}`,
          "Materia Operativa": `${newData.materia.clave}`,
          Agrupador: `${newData.agrupador.value}`,
          Campus: campusList,
          Estatus: `${newData.estatus.value}`,
          Creado: newData.Creado,
          "Creado Por": newData["Creado Por"],
          Modificado: moment().format("DD-MM-YYYY hh:mm:ss"),
          "Modificado Por": userName,
          id: newData.id,
          type: "Materia Agrupador Horas Online Excepción",
        };
        editCatalogoModal(
          payload,
          valueCatalogo,
          getDataTable,
          setLoading,
          setShowTable,
          setTableEdit,
          cleanState,
          setIsModalLoading
        );
      }
      break;
    }
    default:
      break;
  }
};
