import React from "react";
import { Box } from "@material-ui/core";
import NewButton from "../Botones/NewButton";
import { MaterialReactTable } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { MenuItem } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";

export default function CustomTableCatalogo({
  data,
  headers,
  loading,
  isEditarDisabled,
  onEditingRowSave,
  handleDeleteRow,
  isDeleteButton = true,
  valueProceso,
  handleOnClickEdit,
}) {
  if (headers[3]?.id === "Distribucion") {
    headers.forEach((element) => {
      if (element.id !== "Distribucion") {
        element.enableResizing = false;
      }
    });
  }

  const checkValueProceso = (value) => {
    switch (value) {
      case 36:
        return true;
      default:
        return false;
    }
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          marginBottom: 20,
          "& .super-app-theme--false": {
            bgcolor: "#e9e9e9",
            "&:hover": {
              bgcolor: "#cbcbcb",
            },
          },
        }}
      >
        <MaterialReactTable
          columns={headers}
          data={data}
          editingMode="row"
          enableEditing={isEditarDisabled}
          onEditingRowSave={onEditingRowSave}
          enableRowActions={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enableTopToolbar={true}
          enableBottomToolbar
          localization={MRT_Localization_ES}
          enableColumnActions={false}
          enableGlobalFilterModes
          enableColumnResizing
          columnResizeMode="onChange"
          positionGlobalFilter="right"
          muiSearchTextFieldProps={{
            elevation: 0,
            placeholder: `Buscar`,
            sx: {
              ".css-152mnda-MuiInputBase-input-MuiOutlinedInput-input": {
                paddingY: 0,
              },
              width: "400px",
              borderRadius: "15px",
              border: "1px solid #ccc",
              padding: "0 15px",
              fontSize: "15px",
              paddingY: 0,
              marginY: 0,
            },
            variant: "outlined",
          }}
          initialState={{
            showGlobalFilter: true,
          }}
          enableToolbarInternalActions={false}
          state={{ isLoading: loading }}
          filterFns={{
            myCustomFilterFn: (row, id, filterValue) => {
              if (
                row
                  .getValue(id)
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(filterValue?.toLowerCase())
              )
                return true;
              if (
                row.original?.profesor?.[0]?.label
                  ?.toLowerCase()
                  ?.includes(filterValue?.toLowerCase())
              )
                return true;
              return false;
            },
          }}
          globalFilterFn="myCustomFilterFn"
          renderRowActionMenuItems={
            headers[3]?.id !== "Distribucion"
              ? ({ row, closeMenu }) => {
                  if (!isDeleteButton) {
                    return null;
                  } else {
                    return (
                      <Box
                        sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}
                      >
                        <MenuItem
                          key="delete"
                          onClick={() => {
                            handleDeleteRow(row.original);
                            closeMenu();
                          }}
                        >
                          <DeleteIcon
                            color="error"
                            style={{ marginRight: 12 }}
                          />
                          Eliminar
                        </MenuItem>
                      </Box>
                    );
                  }
                }
              : false
          }
          renderRowActions={
            checkValueProceso(valueProceso)
              ? ({ row }) => (
                  <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                    <NewButton
                      customText="Editar"
                      noIcon
                      style={{ padding: "5px 15px" }}
                      onClick={() => {
                        handleOnClickEdit(row);
                      }}
                    />
                  </Box>
                )
              : false
          }
        />
      </Box>
    </>
  );
}
