import React, { useState, useEffect } from "react";
import NewModal from "../../Utils/Visuales/NewModal";
import { Grid } from "@mui/material";
import styles from "./styles.module.css";
import { useSnackbar } from "notistack";
import moment from "moment";
import { postRequest } from "../../Utils/Funciones/requester";
import Select from "react-select";

const EditTraduccionInterfaceNominaAgrupadorHorasOnlineModal = ({
  open,
  close,
  userName,
  info,
  allInfo,
  id,
  getDataTable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState({});
  const estatusOptions = [
    { label: "1", value: "1" },
    { label: "0", value: "0" },
  ];

  useEffect(() => {
    setNewData({
      semanas: info.semanas,
      horas_modulo: info.horas_modulo,
      estatus: { label: `${info.estatus}`, value: `${info.estatus}` },
    });
  }, [info]);

  const handleSubmit = async () => {
    if (newData.semanas === "") {
      enqueueSnackbar("Se debe ingresar una semana", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else if (newData.horas_modulo === "") {
      enqueueSnackbar("Se debe ingresar horas módulo", {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    } else {
      try {
        setLoading(true);
        const payload = {
          id,
          data: allInfo.map((element) => {
            if (element.agrupador === info.agrupador) {
              return {
                ...element,
                modificado: moment().format("YYYY-MM-DD hh:mm:ss"),
                modificado_por: userName,
                semanas: parseInt(newData.semanas),
                horas_modulo: parseFloat(newData.horas_modulo),
                estatus: newData.estatus.value,
              };
            }
            return element;
          }),
        };

        const response = await postRequest("/parametro_reglas", payload);

        if (response.s === "OK") {
          enqueueSnackbar(response.m, {
            variant: "success",
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
          getDataTable(id);
          close(false);
        } else {
          enqueueSnackbar(response.m, {
            variant: "error",
            autoHideDuration: null,
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          });
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleChangeWeek = (e, key) => {
    const inputValue = e.target.value;
    if (inputValue === ".") {
      return;
    }
    if (/^\d+$/.test(inputValue) || inputValue === "") {
      setNewData((prev) => ({ ...prev, [key]: inputValue }));
    }
  };
  const handleChangeModule = (e, key) => {
    const inputValue = e.target.value;
    if (inputValue === ".") {
      return;
    }
    if (/^\d*\.?\d*$/.test(inputValue) || inputValue === "") {
      setNewData((prev) => ({ ...prev, [key]: inputValue }));
    }
  };

  const handleClose = () => {
    close(false);
  };

  const handleChangeStatus = (e) => {
    setNewData((prev) => ({ ...prev, estatus: e }));
  };

  return (
    <NewModal
      title={`Configuración Agrupador Horas Online`}
      open={open}
      handleClose={handleClose}
      maxWidth={800}
      height={200}
      loading={loading}
      overflowY={"unset"}
      handleSubmit={handleSubmit}
    >
      <div style={{ padding: "25px" }}>
        <Grid
          container
          spacing={1}
          style={{
            marginBottom: "30px",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <Grid item xs={3}>
            <label className={styles.inputLabel}>Agrupador:</label>
          </Grid>
          <Grid item xs={8}>
            <input
              className={styles.filterInput}
              value={info.agrupador}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <label className={styles.inputLabel}>Semanas:</label>
          </Grid>
          <Grid item xs={8}>
            <input
              className={styles.filterInput}
              onChange={(e) => {
                handleChangeWeek(e, "semanas");
              }}
              value={newData.semanas}
            />
          </Grid>
          <Grid item xs={3}>
            <label className={styles.inputLabel}>Horas Módulo:</label>
          </Grid>
          <Grid item xs={8}>
            <input
              className={styles.filterInput}
              onChange={(e) => {
                handleChangeModule(e, "horas_modulo");
              }}
              value={newData.horas_modulo}
            />
          </Grid>
          <Grid item xs={3}>
            <label className={styles.inputLabel}>Estatus:</label>
          </Grid>
          <Grid item xs={8}>
            <Select
              classNamePrefix="select-modal"
              options={estatusOptions}
              styles={{
                container: (styles) => ({ ...styles, zIndex: 19 }),
                singleValue: (styles) => ({ ...styles, textAlign: "center" }),
              }}
              onChange={handleChangeStatus}
              value={newData.estatus}
            />
          </Grid>
        </Grid>
      </div>
    </NewModal>
  );
};

export default EditTraduccionInterfaceNominaAgrupadorHorasOnlineModal;
