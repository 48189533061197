import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import AsyncSelect from "react-select/async";
import { CircularProgress } from "@mui/material";

import ModalSelect from "../../Utils/ModalSelect";
import { getCampusCatalog } from "../../Utils/Funciones/getCampusCatalog";
import { getTraduccionInterfaceNominaagrupadorHorasOnline } from "../../Utils/Funciones/getTraduccionInterfaceNominaagrupadorHorasOnline";
import { getSchools } from "../../Utils/Funciones/getSchools";
import MultiSelectCampus from "../../Utils/MultiSelectCampus";
import ModalInput from "../../Utils/ModalInput";
import { getRequest } from "../../Utils/Funciones/requester";
import { getMateriaOperartiva } from "../../Utils/Funciones/getMateriaOperartiva";

const CreateMateriaAgrupadorHorasOnlineExcepcion = ({
  newData,
  setNewData,
  enqueueSnackbar,
}) => {
  let timer;
  const minChars = 3;
  const waitTime = 2000;

  const [isLoading, setIsLoading] = useState(true);
  const [campusSelected, setCampusSelected] = useState([]);
  const [options, setOptions] = useState({
    escuelas: [],
    campus: [],
    materia: [],
    agrupador: [],
  });
  useEffect(() => {
    const responseData = {};
    getMateriaOperartiva().then((materia) => {
      const activeMateria = materia.filter((item) => item.estatus === "1");
      responseData.materia = activeMateria.find(
        (element) => newData["materia"]?.clave === element.clave
      );
    });
    getSchools().then((escuelas) => {
      setOptions((prev) => ({ ...prev, escuelas }));
      responseData.escuelas = escuelas.find(
        (escuela) => newData["escuelas"]?.clave === escuela.clave
      );
    });
    getCampusCatalog().then((campus) => {
      const campusSelected = newData["campus"]?.map((item) => item.clave);
      const activeCampus = campus
        .filter((item) => item.active === "1")
        .map((item) => ({
          ...item,
          isActive: campusSelected?.includes(item.clave) ? true : false,
        }));
      setCampusSelected([
        { label: "Todos", value: "Todos", isActive: false },
        ...activeCampus,
      ]);
      responseData.campus = activeCampus.filter((element) => element.isActive);
    });
    getTraduccionInterfaceNominaagrupadorHorasOnline().then((agrupador) => {
      const activeAgrupador = agrupador?.filter(
        (element) => element?.estatus === "1"
      );
      setOptions((prev) => ({ ...prev, agrupador: activeAgrupador }));
      responseData.agrupador = agrupador.find(
        (element) => newData["agrupador"]?.clave === element.clave
      );
    });

    const timeoutId = setTimeout(() => {
      setIsLoading(false);
      setNewData((prev) => ({
        ...prev,
        ...responseData,
        estatus: { label: prev["Estatus"], value: prev["Estatus"] },
      }));
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleChageSelect = (change, key) => {
    setNewData((prev) => ({ ...prev, [key]: change }));
  };

  const handleChangeOpcionCampus = (selected) => {
    if (selected.label === "Todos") {
      const findTodos = campusSelected.find(
        (campus) => campus.label === "Todos"
      );
      const newCampusSelected = campusSelected.map((campus) => ({
        ...campus,
        isActive: !findTodos.isActive,
      }));
      setCampusSelected(newCampusSelected);
      setNewData((prev) => ({
        ...prev,
        campus: newCampusSelected.filter((item) => item.isActive),
      }));
    } else {
      const newPrev = campusSelected.map((campus) => {
        if (campus.value === selected.value) {
          return { ...campus, isActive: !campus.isActive };
        }
        return { ...campus };
      });
      const allActive = newPrev.every(
        (campus) => campus.value === "0" || campus.isActive
      );
      const newCampusSelected = newPrev.map((campus) => {
        if (campus.label === "Todos") {
          return {
            ...campus,
            isActive: allActive,
          };
        }
        return { ...campus };
      });
      setCampusSelected(newCampusSelected);
      setNewData((prev) => ({
        ...prev,
        campus: newCampusSelected.filter((item) => item.isActive),
      }));
    }
  };

  async function searchProfesor(valor) {
    try {
      const options = [];
      const consulta = `/buscar?tipo=materias_operativas&valor=${valor.toUpperCase()}`;
      const response = await getRequest(consulta);

      if (response.s === "OK") {
        response.d.forEach((item) => {
          options.push({
            label: item.clave,
            value: item.id,
            clave: item.clave,
          });
        });

        return options;
      } else {
        enqueueSnackbar(response.m, {
          variant: "error",
          autoHideDuration: null,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error, {
        variant: "error",
        autoHideDuration: null,
        anchorOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      });
    }
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      clearTimeout(timer);
      if (inputValue.length >= minChars) {
        timer = setTimeout(() => {
          resolve(searchProfesor(inputValue));
        }, waitTime);
      } else {
        resolve([]);
      }
    });

  return (
    <>
      {isLoading ? (
        <div style={{ textAlign: "center", paddingTop: 30 }}>
          <CircularProgress size={50} />
        </div>
      ) : (
        <Grid style={{ padding: 20, paddingTop: 0 }}>
          <br></br>
          <ModalSelect
            label={"Clave Escuela"}
            options={options.escuelas}
            onChange={(event) => handleChageSelect(event, "escuelas")}
            value={newData.escuelas}
          />
          <Grid
            container
            style={{ marginTop: 20, display: "flex", alignItems: "center" }}
          >
            <Grid item sm={2} style={{ textAlign: "end", marginRight: 20 }}>
              <label>Materia Operativa:</label>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <AsyncSelect
                className="select-modal-container"
                classNamePrefix="select-modal"
                loadOptions={promiseOptions}
                onChange={(event) => handleChageSelect(event, "materia")}
                isClearable={true}
                value={newData.materia}
              />
            </Grid>
          </Grid>
          <ModalSelect
            label={"Agrupador"}
            options={options.agrupador}
            onChange={(event) => handleChageSelect(event, "agrupador")}
            value={newData.agrupador}
          />
          <Grid
            container
            style={{ marginTop: 20, display: "flex", alignItems: "center" }}
          >
            <Grid item sm={2} style={{ textAlign: "end", marginRight: 20 }}>
              <label>Campus:</label>
            </Grid>
            <Grid item style={{ flexGrow: 1 }}>
              <MultiSelectCampus
                options={campusSelected}
                onChange={handleChangeOpcionCampus}
                value={{
                  value: `${
                    campusSelected.filter((item) => item.isActive).length
                  }`,
                  label: `Total: ${
                    campusSelected.filter((item) => item.isActive).length
                  }`,
                }}
              />
            </Grid>
          </Grid>
          <ModalInput lable={"Estatus:"} defaultValue={"1"} disabled={true} />
        </Grid>
      )}
    </>
  );
};

export default CreateMateriaAgrupadorHorasOnlineExcepcion;
