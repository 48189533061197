export const materiaAgrupadorHorasOnlineExcepcionHeaders = [
  {
    accessorKey: "Clave Escuela",
    header: "Clave Escuela",
    enableEditing: false,
    enableGlobalFilter: true,
  },
  {
    accessorKey: "Materia Operativa",
    header: "Materia Operativa",
    enableEditing: false,
    enableGlobalFilter: true,
  },
  {
    accessorKey: "Agrupador",
    header: "Agrupador",
    enableEditing: false,
    enableGlobalFilter: true,
  },
  {
    accessorKey: "Campus",
    header: "Campus",
    enableEditing: false,
    enableGlobalFilter: true,
  },
  {
    accessorKey: "Estatus",
    header: "Estatus",
    enableEditing: false,
    enableGlobalFilter: true,
  },
  {
    accessorKey: "Modificado",
    header: "Modificado",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Modificado Por",
    header: "Modificado Por",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Creado",
    header: "Creado",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "Creado Por",
    header: "Creado Por",
    enableEditing: false,
    enableGlobalFilter: false,
  },
  {
    accessorKey: "id",
    header: "id",
    size: 10,
    enableEditing: false,
    enableGlobalFilter: false,
  },
];
